// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {Array} 36 best palettes from coolors.co */
const coolors = [
  ['#fe938c', '#e6b89c', '#ead2ac', '#9cafb7', '#4281a4'],
  ['#5bc0eb', '#fde74c', '#9bc53d', '#e55934', '#fa7921'],
  ['#ed6a5a', '#f4f1bb', '#9bc1bc', '#5ca4a9', '#e6ebe0'],
  ['#ef476f', '#ffd166', '#06d6a0', '#118ab2', '#073b4c'],
  ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#6fffe9'],
  ['#003049', '#d62828', '#f77f00', '#fcbf49', '#eae2b7'],
  ['#bce784', '#5dd39e', '#348aa7', '#525174', '#513b56'],
  ['#000000', '#14213d', '#fca311', '#e5e5e5', '#ffffff'],
  ['#9c89b8', '#f0a6ca', '#efc3e6', '#f0e6ef', '#b8bedd'],
  ['#114b5f', '#028090', '#e4fde1', '#456990', '#f45b69'],
  ['#f2d7ee', '#d3bcc0', '#a5668b', '#69306d', '#0e103d'],
  ['#dcdcdd', '#c5c3c6', '#46494c', '#4c5c68', '#1985a1'],
  ['#22223b', '#4a4e69', '#9a8c98', '#c9ada7', '#f2e9e4'],
  ['#114b5f', '#1a936f', '#88d498', '#c6dabf', '#f3e9d2'],
  ['#ff9f1c', '#ffbf69', '#ffffff', '#cbf3f0', '#2ec4b6'],
  ['#3d5a80', '#98c1d9', '#e0fbfc', '#ee6c4d', '#293241'],
  ['#06aed5', '#086788', '#f0c808', '#fff1d0', '#dd1c1a'],
  ['#011627', '#f71735', '#41ead4', '#fdfffc', '#ff9f1c'],
  ['#f6511d', '#ffb400', '#00a6ed', '#7fb800', '#0d2c54'],
  ['#d8dbe2', '#a9bcd0', '#58a4b0', '#373f51', '#1b1b1e'],
  ['#7bdff2', '#b2f7ef', '#eff7f6', '#f7d6e0', '#f2b5d4'],
  ['#2d3142', '#4f5d75', '#bfc0c0', '#ffffff', '#ef8354'],
  ['#13293d', '#006494', '#247ba0', '#1b98e0', '#e8f1f2'],
  ['#ffb997', '#f67e7d', '#843b62', '#0b032d', '#74546a'],
  ['#3d315b', '#444b6e', '#708b75', '#9ab87a', '#f8f991'],
  ['#cfdbd5', '#e8eddf', '#f5cb5c', '#242423', '#333533'],
  ['#083d77', '#ebebd3', '#f4d35e', '#ee964b', '#f95738'],
  ['#20bf55', '#0b4f6c', '#01baef', '#fbfbff', '#757575'],
  ['#05668d', '#427aa1', '#ebf2fa', '#679436', '#a5be00'],
  ['#ffbf00', '#e83f6f', '#2274a5', '#32936f', '#ffffff'],
  ['#292f36', '#4ecdc4', '#f7fff7', '#ff6b6b', '#ffe66d'],
  ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69'],
  ['#c9cba3', '#ffe1a8', '#e26d5c', '#723d46', '#472d30'],
  ['#ffa69e', '#faf3dd', '#b8f2e6', '#aed9e0', '#5e6472'],
  ['#1be7ff', '#6eeb83', '#e4ff1a', '#e8aa14', '#ff5714'],
  ['#1b998b', '#2d3047', '#fffd82', '#ff9b71', '#e84855'],
]

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default coolors
