// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {Array} 100 most popular palettes from ColorHunt */
const colorhunt = [
  ['#eeeeee', '#00adb5', '#393e46', '#222831'],
  ['#6a2c70', '#b83b5e', '#f08a5d', '#f9ed69'],
  ['#95e1d3', '#eaffd0', '#fce38a', '#f38181'],
  ['#eaeaea', '#ff2e63', '#252a34', '#08d9d6'],
  ['#fc5185', '#f5f5f5', '#3fc1c9', '#364f6b'],
  ['#ffffd2', '#fcbad3', '#aa96da', '#a8d8ea'],
  ['#71c9ce', '#a6e3e9', '#cbf1f5', '#e3fdfd'],
  ['#40514e', '#11999e', '#30e3ca', '#e4f9f5'],
  ['#8785a2', '#f6f6f6', '#ffe2e2', '#ffc7c7'],
  ['#abedd8', '#46cdcf', '#3d84a8', '#48466d'],
  ['#112d4e', '#3f72af', '#dbe2ef', '#f9f7f7'],
  ['#ffde7d', '#f6416c', '#f8f3d4', '#00b8a9'],
  ['#53354a', '#903749', '#e84545', '#2b2e4a'],
  ['#311d3f', '#522546', '#88304e', '#e23e57'],
  ['#14ffec', '#0d7377', '#323232', '#212121'],
  ['#a5dee5', '#e0f9b5', '#fefdca', '#ffcfdf'],
  ['#61c0bf', '#bbded6', '#fae3d9', '#ffb6b9'],
  ['#ffaaa5', '#ffd3b6', '#dcedc1', '#a8e6cf'],
  ['#cca8e9', '#c3bef0', '#cadefc', '#defcf9'],
  ['#3e4149', '#444f5a', '#ff9999', '#ffc8c8'],
  ['#ff165d', '#ff9a00', '#f6f7d7', '#3ec1d3'],
  ['#521262', '#6639a6', '#3490de', '#6fe7dd'],
  ['#355c7d', '#6c5b7b', '#c06c84', '#f67280'],
  ['#ffd460', '#f07b3f', '#ea5455', '#2d4059'],
  ['#edb1f1', '#d59bf6', '#9896f1', '#8ef6e4'],
  ['#99ddcc', '#f6f6f6', '#ffe2e2', '#bad7df'],
  ['#c4edde', '#7ac7c4', '#f73859', '#384259'],
  ['#cabbe9', '#ffcef3', '#fdfdfd', '#a1eafb'],
  ['#ff8c94', '#ffaaa6', '#ffd3b5', '#dcedc2'],
  ['#ff5722', '#eeeeee', '#00adb5', '#303841'],
  ['#283c63', '#928a97', '#fbe8d3', '#f85f73'],
  ['#fecea8', '#ff847c', '#e84a5f', '#2a363b'],
  ['#1e2022', '#52616b', '#c9d6df', '#f0f5f9'],
  ['#ff7e67', '#fafafa', '#a2d5f2', '#07689f'],
  ['#e8ffe8', '#a6fff2', '#74f9ff', '#00e0ff'],
  ['#a56cc1', '#a6acec', '#ace7ef', '#cefff1'],
  ['#ff9de2', '#8c82fc', '#b693fe', '#7effdb'],
  ['#f57170', '#f5f5f5', '#10ddc2', '#15b7b9'],
  ['#625772', '#f9a1bc', '#fefaec', '#a9eee6'],
  ['#769fcd', '#b9d7ea', '#d6e6f2', '#f7fbfc'],
  ['#0dceda', '#6ef3d6', '#c6fce5', '#ebfffa'],
  ['#f25d9c', '#b61aae', '#590d82', '#0c056d'],
  ['#878ecd', '#b9bbdf', '#dde7f2', '#dff4f3'],
  ['#ffaaa5', '#ffd3b6', '#fdffab', '#a8e6cf'],
  ['#d7fbe8', '#9df3c4', '#62d2a2', '#1fab89'],
  ['#fc85ae', '#9e579d', '#574b90', '#303a52'],
  ['#fbafaf', '#f2c6b4', '#f3e8cb', '#99e1e5'],
  ['#a6d0e4', '#f9ffea', '#ffecda', '#d4a5a5'],
  ['#eeeeee', '#d72323', '#3a4750', '#303841'],
  ['#c5e3f6', '#fc5c9c', '#fccde2', '#fcefee'],
  ['#7e6bc4', '#c79ecf', '#d6c8ff', '#fef0ff'],
  ['#ed8d8d', '#8d6262', '#4d4545', '#393232'],
  ['#dbedf3', '#f73859', '#404b69', '#283149'],
  ['#fa4659', '#f0fff3', '#c6f1e7', '#11cbd7'],
  ['#537780', '#11d3bc', '#55e9bc', '#fffcca'],
  ['#f5c7f7', '#a393eb', '#5e63b6', '#27296d'],
  ['#004a7c', '#005691', '#e8f1f5', '#fafafa'],
  ['#ffc93c', '#ff9a3c', '#ff6f3c', '#155263'],
  ['#fff5a5', '#ffaa64', '#ff8264', '#ff6464'],
  ['#7098da', '#6eb6ff', '#90f2ff', '#e0fcff'],
  ['#f3f798', '#eab4f8', '#fcc8f8', '#c7f5fe'],
  ['#e3e3e3', '#f95959', '#455d7a', '#233142'],
  ['#f5f5f5', '#d3d4d8', '#3fbac2', '#4d606e'],
  ['#bfcfff', '#c8e7ed', '#ffffc2', '#ffa5a5'],
  ['#625772', '#f38181', '#fefaec', '#a9eee6'],
  ['#ffbd39', '#e61c5d', '#930077', '#3a0088'],
  ['#8971d0', '#7dace4', '#95e8d7', '#adf7d1'],
  ['#e67a7a', '#ffebb7', '#fff4e1', '#9ddcdc'],
  ['#d9faff', '#00bbf0', '#005792', '#00204a'],
  ['#f76b8a', '#fcfefe', '#eaf6f6', '#66bfbf'],
  ['#f2f4c3', '#ffdcf5', '#fdc7ff', '#c7f3ff'],
  ['#d2ecf9', '#1891ac', '#1f5f8b', '#253b6e'],
  ['#649dad', '#66c6ba', '#a4e5d9', '#c8f4de'],
  ['#c86b85', '#e6a4b4', '#f3d7ca', '#f5eee6'],
  ['#fbac91', '#fbe1b6', '#7fdfd4', '#a7efe9'],
  ['#fecea8', '#ff847b', '#e84a5f', '#2a363b'],
  ['#6c5b7c', '#c06c84', '#f67280', '#f8b595'],
  ['#4a266a', '#7f4a88', '#de95ba', '#ffd9e8'],
  ['#ff7c38', '#e03e36', '#b80d57', '#700961'],
  ['#2f9296', '#46b7b9', '#87dfd6', '#dff5f2'],
  ['#f67280', '#c06c84', '#6c5b7b', '#35477d'],
  ['#7c7575', '#b8b0b0', '#dfd3d3', '#fbf0f0'],
  ['#c0ffc2', '#fdffba', '#ffeab6', '#f69d9d'],
  ['#163172', '#1e56a0', '#d6e4f0', '#f6f6f6'],
  ['#aedefc', '#fff6f6', '#ffdfdf', '#fb929e'],
  ['#a7ff83', '#17b978', '#086972', '#071a52'],
  ['#ba52ed', '#ff99fe', '#a4f6f9', '#e4fffe'],
  ['#537791', '#c1c0b9', '#f7f6e7', '#e7e6e1'],
  ['#f2bbbb', '#ed93cb', '#ca82f8', '#a1d9ff'],
  ['#f8ecfd', '#c264fe', '#a82ffc', '#7a08fa'],
  ['#00fff5', '#00adb5', '#393e46', '#222831'],
  ['#1f4e5f', '#79a8a9', '#aacfd0', '#f4f7f7'],
  ['#80d6ff', '#edf798', '#fab57a', '#f06868'],
  ['#8f8787', '#ebcbae', '#f9f9f9', '#a6e4e7'],
  ['#2eb872', '#a3de83', '#feffe4', '#fa4659'],
  ['#355c7d', '#c06c84', '#f67280', '#f8b195'],
  ['#d988bc', '#ffa8b8', '#ffd2a5', '#ffffc1'],
  ['#70a1d7', '#a1de93', '#f7f48b', '#f47c7c'],
  ['#34495e', '#5da0a2', '#aacfd0', '#f4f7f7'],
  ['#f19584', '#fea386', '#f6e4c4', '#29c6cd'],
]

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default colorhunt
