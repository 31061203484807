// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import getRandomPalette from '../color/getRandomPalette'
import muzli from '../color/muzli'

import array from '../../methods/array'
import shuffle from '../../methods/shuffle'
import randInt from '../../methods/randInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const w = 1280
const h = 728
// const p = getRandomPalette()
// const palette = shuffle(p)
const palette = muzli[2]

let domCache

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const SVG = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1280 728"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0" y="0" width={w} height={h} stroke="none" fill={palette[0]} />$
    {array(270).map((i) => (
      <circle
        transform={`translate(${w / 2}, ${h / 2}) rotate(${randInt(360)})`}
        cx="0"
        cy="0"
        r={`${50 + i * 3}`}
        fill="none"
        stroke={`${palette[randInt(1, palette.length)]}`}
        stroke-width={`${randInt(2, 32)}`}
        stroke-dasharray={`${array(200)
          .map(() => randInt(800))
          .join(' ')}`}
      />
    ))}
    {array(270).map((i) => (
      <circle
        transform={`translate(${w / 2}, ${h / 2}) rotate(${randInt(360)})`}
        cx="0"
        cy="0"
        r={`${30 + i * 3}`}
        fill="none"
        stroke={`${palette[randInt(1, palette.length)]}`}
        stroke-width={`${randInt(5 + i / 3)}`}
        stroke-dasharray={`${array(200)
          .map((j) => (j % 2 == 1 ? randInt(100) : randInt(1, 6)))
          .join(' ')}`}
      />
    ))}
  </svg>
)

/**  */
class Graph extends React.PureComponent {
  /** standard constructor */
  constructor(props) {
    super(props)

    // this.ref = React.createRef()
  }

  // /** [componentDidMount description] */
  // componentDidMount() {
  //   if (domCache) {
  //     this.ref.current.appendChild(domCache.cloneNode(true))
  //   } else {
  //     domCache = this.ref.current.firstChild
  //   }
  // }

  /** [render description] */
  render() {
    // const children = domCache ? null : <SVG />

    return <SVG />
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Graph
