// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {Array} Palettes from ColorLeap.app */
const colorleap = [
  ['#efdec4', '#f8ab51', '#ee8927', '#151829', '#080705'],
  ['#f9f4e4', '#eebb4e', '#ad7432', '#6dbdc4', '#0a203f'],
  ['#7f7340', '#c6b28d', '#bb8642', '#914532', '#511f20'],
  ['#857e6b', '#d0c1ae', '#c8a15c', '#ba4033', '#8c433a'],
  ['#bcbbb7', '#cd9e34', '#622834', '#164072', '#322424'],
  ['#655255', '#b48959', '#733d25', '#42150f', '#100606'],
  ['#f8ead0', '#e1c7a2', '#7a786b', '#4f5146', '#3b372e'],
  ['#f8ecd9', '#febe8e', '#c87a4b', '#31322c', '#0f1411'],
  ['#dfe5e3', '#bab2ad', '#e7b409', '#8c4501', '#3f0d0a'],
  ['#fccc7a', '#d7a35a', '#9a572d', '#562f20', '#3e2419'],
  ['#ffc253', '#df8601', '#8f3b03', '#701b00', '#020200'],
  ['#e1e1e1', '#d7bf47', '#186f2e', '#0550ab', '#072053'],
  ['#8f965f', '#c49624', '#a56333', '#85321f', '#453633'],
  ['#f7ebd4', '#b98556', '#715c49', '#534a41', '#473025'],
  ['#97151f', '#dfe5e5', '#176c6a', '#013b44', '#212220'],
  ['#f4e8b8', '#eec30e', '#8f0100', '#058187', '#0c4da7'],
  ['#b9b2a3', '#404948', '#d6af6c', '#b44541', '#632621'],
  ['#cda561', '#ab672d', '#cac2b3', '#989fb1', '#174073'],
  ['#f0f1ec', '#c98e43', '#98603a', '#64463c', '#412e2b'],
  ['#949ca3', '#334762', '#b39a67', '#d8ae46', '#b24c2e'],
  ['#e3c878', '#b15142', '#7e3728', '#52544e', '#363732'],
  ['#e0dedf', '#6ec0b8', '#257f6a', '#9b6133', '#664326'],
  ['#eac485', '#aa857a', '#936435', '#81241f', '#362a2c'],
  ['#e4d4cf', '#8e9b6e', '#e2ba3b', '#bb742b', '#a81d1c'],
  ['#cb9c53', '#826e49', '#b04130', '#6c3d35', '#38222f'],
  ['#dab387', '#b9793b', '#d49c35', '#b23306', '#1f4062'],
  ['#a45b40', '#d5b698', '#bc8a5c', '#8d603f', '#3f5c75'],
  ['#777453', '#b15441', '#daab6c', '#7d776f', '#745d5a'],
  ['#fef7ee', '#fef000', '#fb0002', '#1c82eb', '#190c28'],
  ['#76714e', '#e8c7a6', '#e0a658', '#c24f46', '#456a6c'],
  ['#d5c2a2', '#cb9323', '#926d33', '#3f5756', '#172639'],
  ['#f7a38a', '#f48a5b', '#b7a78d', '#deae62', '#524a47'],
  ['#5a8693', '#dfb064', '#e8e5de', '#b3beb0', '#576359'],
  ['#6c8194', '#b8985b', '#86373a', '#293447', '#443240'],
  ['#ece8df', '#fabc55', '#d08642', '#a04533', '#59231a'],
  ['#cd9d4f', '#9d7538', '#277d53', '#275d3d', '#222b1c'],
  ['#eeb765', '#748d88', '#e7b489', '#d59576', '#564e4b'],
  ['#6aa1b6', '#799d83', '#e2ce91', '#ad917c', '#d66e61'],
  ['#bfa87e', '#b1906d', '#626a5d', '#4f5144', '#342818'],
  ['#e0ccb1', '#c1a185', '#b0836b', '#aeb2a1', '#7e8072'],
  ['#ede5d5', '#ac9a82', '#6a5a43', '#347c8d', '#1a444c'],
  ['#e2ded2', '#aca794', '#b3cfdb', '#8fafbc', '#5b737d'],
  ['#eecf95', '#caa067', '#b78742', '#614b28', '#3f240b'],
  ['#e2bf4a', '#913527', '#bcb297', '#767051', '#4f483d'],
  ['#e2ba9a', '#d0a07b', '#e5a367', '#9d652e', '#212530'],
  ['#878e7c', '#7e7c43', '#eea23b', '#d8491d', '#855c3e'],
  ['#ecd0bb', '#d26e3a', '#929bac', '#013d6f', '#071e44'],
  ['#ca382b', '#e4d4b3', '#b3a692', '#938775', '#373634'],
  ['#eee1cd', '#e1a850', '#79715c', '#53676e', '#2a2e31'],
  ['#fcecc3', '#f1db8e', '#8d9d81', '#63806c', '#3d5958'],
  ['#f0dfc3', '#dea93d', '#a6785e', '#7f6765', '#50464e'],
  ['#f3e2c8', '#f0c789', '#a6b098', '#4b795c', '#bc2744'],
  ['#f36b3c', '#ebd7bc', '#c9b099', '#84674c', '#3e3832'],
  ['#d7bf9b', '#dab04e', '#be6e33', '#68603c', '#585451'],
  ['#e8e1c9', '#ebd17c', '#f0623c', '#566680', '#28282a'],
  ['#f0c95a', '#db8647', '#ca443b', '#62968e', '#5e5c67'],
  ['#ead2ba', '#f3b234', '#f56d4e', '#2b3e4d', '#332a2a'],
  ['#f1ddc5', '#e3b79e', '#df5842', '#877e68', '#322e2d'],
  ['#edd9c0', '#f3a548', '#da393f', '#186c8f', '#42353e'],
  ['#8c8d41', '#6e6e42', '#5a5f45', '#766339', '#303325'],
  ['#eab57f', '#4a7d90', '#939249', '#e3ae3c', '#c24f1a'],
  ['#d6c3b5', '#9e997b', '#5b5e4a', '#3b3d30', '#192320'],
  ['#cebfb9', '#71614c', '#b49e6f', '#c17121', '#3c371a'],
  ['#e6aa30', '#d9892b', '#c33809', '#766135', '#46381d'],
  ['#c48d54', '#9c6c58', '#62685c', '#22404a', '#243431'],
  ['#e0dcd9', '#aa9965', '#7e7a5a', '#414538', '#1e2428'],
  ['#ebc371', '#deaa44', '#ac7534', '#706738', '#332c1a'],
  ['#88b2ca', '#204d72', '#fa971e', '#979089', '#3a3c39'],
  ['#7c7848', '#e3c736', '#6a7490', '#9d1f35', '#5d242a'],
  ['#e7d1a9', '#f1c202', '#807474', '#c5484c', '#633c3e'],
  ['#a99142', '#86473e', '#737b6c', '#4d6970', '#5f4e4d'],
  ['#5ca0a3', '#e3b13f', '#e86b4b', '#d33f4a', '#8e5133'],
  ['#21809e', '#d8c19f', '#b98748', '#ad0327', '#2d1a14'],
  ['#c8a87f', '#d49e34', '#c29042', '#614b3c', '#503e3a'],
  ['#e7c484', '#7d9461', '#687e6d', '#c54a25', '#8c5625'],
  ['#ebd7c3', '#e7b67b', '#d17028', '#809181', '#313c3e'],
  ['#dcc978', '#d39e04', '#82875d', '#4b5733', '#1e2214'],
  ['#4a564c', '#c19e5c', '#7c531a', '#7c3314', '#572d14'],
  ['#f5f8f2', '#919484', '#fab072', '#f57624', '#a33318'],
  ['#778b88', '#f4dda4', '#e89104', '#c12205', '#7c4c10'],
  ['#94a08a', '#f6d766', '#d78a72', '#7d8ead', '#7e6a7b'],
  ['#e5d0a3', '#db9b75', '#db1b12', '#2f546d', '#0b2540'],
  ['#deb555', '#e28f0c', '#477f9a', '#86a201', '#295934'],
  ['#eaa651', '#e76134', '#e73430', '#506536', '#1a2129'],
  ['#bed180', '#63a3bc', '#d3a27e', '#fd8e59', '#4d4341'],
  ['#959365', '#c2b2a2', '#a3947f', '#a6522d', '#7a4938'],
  ['#f7eee2', '#b0c8ca', '#fb8c82', '#bfa16f', '#312613'],
  ['#e3d3ba', '#e5a40f', '#d3742b', '#869d8c', '#445a54'],
  ['#65b1af', '#d44634', '#e4c8a9', '#a889a3', '#6f4b5e'],
  ['#d9c06b', '#d7714d', '#52675d', '#245168', '#4e3f43'],
  ['#e5a914', '#dc0502', '#005e2a', '#058f96', '#031f34'],
  ['#a59e69', '#c99946', '#c05934', '#57897b', '#34362f'],
  ['#e8b797', '#edb126', '#aa341b', '#302a46', '#2d1a1e'],
  ['#fbc500', '#d4340a', '#90241c', '#3d1c66', '#1c1140'],
  ['#cee5cb', '#f9c61f', '#b9111e', '#015f9f', '#08070f'],
  ['#f3e2c8', '#fef200', '#f7941d', '#ed1b24', '#0270ba'],
  ['#fbc592', '#a07739', '#513207', '#eb3b24', '#324885'],
  ['#afa856', '#da8851', '#878e87', '#ab3855', '#292a2d'],
  ['#f2d7bd', '#496257', '#f9b93f', '#356281', '#2c3233'],
  ['#d8b888', '#ce8f59', '#99697e', '#79445e', '#421c31'],
  ['#f4cfa5', '#fdaa26', '#ff691b', '#636b35', '#3e2a1f'],
  ['#d6b980', '#d2a639', '#8d3004', '#3e451b', '#1b191a'],
  ['#dfe5dd', '#a8989a', '#c73401', '#5c3d26', '#170f02'],
  ['#7ba99e', '#e0aa24', '#a05300', '#481929', '#192637'],
  ['#8ab889', '#e3b38f', '#f0eac7', '#e6a93e', '#4f4d34'],
  ['#f9e3b8', '#ebb85d', '#db9501', '#d26500', '#25251b'],
  ['#c14f11', '#773621', '#abb1a5', '#1d354b', '#0e0d1e'],
  ['#c1cddc', '#516ebc', '#00529c', '#153477', '#050f2b'],
  ['#dad9c4', '#ebb821', '#d98025', '#bf4430', '#33302a'],
  ['#eae5c6', '#f4da61', '#db8479', '#6179b5', '#272d2d'],
  ['#f4d6c3', '#efb16c', '#828461', '#ab4253', '#485f66'],
  ['#f7df19', '#b63e30', '#d9d9cd', '#85847f', '#1a1c27'],
  ['#e3be76', '#ce9f29', '#b85b2b', '#506058', '#674230'],
  ['#e6d8b6', '#d6b683', '#dea500', '#8fa364', '#514a38'],
  ['#a1a799', '#b06034', '#c29288', '#7e737e', '#273443'],
  ['#8aa5a0', '#50566e', '#c3b47b', '#7e8a63', '#404a3b'],
  ['#d9cdb3', '#b3c9bd', '#497791', '#666e7f', '#333947'],
  ['#cecabe', '#d8b321', '#b33447', '#505d78', '#3a465f'],
  ['#d4c397', '#8a794b', '#b7bf85', '#929e57', '#3c473c'],
  ['#38526e', '#dac9af', '#c19c82', '#ae7757', '#6c4838'],
  ['#f6c970', '#fae804', '#ee1d19', '#0e68ad', '#031e4b'],
  ['#909ca5', '#f1ce54', '#cf540d', '#6ea2c3', '#181e34'],
  ['#df9d09', '#c22e14', '#446b4c', '#d9b787', '#493f24'],
  ['#ffffff', '#fec3f0', '#fa110d', '#54282a', '#2d2d2d'],
  ['#cccecd', '#8a8278', '#3b678c', '#d4a78d', '#cd4e48'],
  ['#e3d697', '#e4ad2e', '#aa4534', '#606574', '#3f4064'],
  ['#f4f4f2', '#afc8b8', '#4881b2', '#de443a', '#9e3e32'],
  ['#d2998d', '#d24541', '#eddecb', '#bfb8a6', '#615b5b'],
  ['#fdf6ee', '#eddaba', '#b2b6a0', '#e66111', '#000000'],
  ['#fda176', '#fd4a1f', '#fee9b0', '#f4b04d', '#58b185'],
  ['#f4f2ed', '#dad5cf', '#6b83ab', '#cf4626', '#45423e'],
  ['#fde58e', '#f2ca66', '#73d6f5', '#077f92', '#1c2227'],
  ['#ddc289', '#e1ab00', '#c95635', '#4b4d54', '#39362d'],
  ['#e9e3d3', '#f1da00', '#e10602', '#33453d', '#2e3035'],
  ['#982312', '#6a140c', '#e1dfae', '#9ca888', '#272020'],
  ['#ffda32', '#ea2768', '#4174b3', '#5a4d88', '#534856'],
  ['#3565a3', '#023d79', '#f5d5d6', '#fa2d19', '#6a1012'],
  ['#fedeb8', '#abdacb', '#fdfd00', '#fd4126', '#2c4743'],
  ['#94afc0', '#2289b6', '#f7bd40', '#e0160a', '#871008'],
  ['#9dafa5', '#f4e1b5', '#e0c38a', '#da391b', '#2d2f23'],
  ['#4e84ba', '#f9ebc9', '#f0d137', '#e95913', '#5f5b49'],
  ['#fbbb04', '#d53a12', '#6b7c84', '#686444', '#421f04'],
  ['#fef2ab', '#e5d098', '#4f7379', '#385a5c', '#000506'],
  ['#eecb28', '#a05a48', '#e4e2e3', '#807c8a', '#14121e'],
  ['#ede3cb', '#e6d53c', '#81abaa', '#ef594b', '#443a38'],
  ['#f8e4bc', '#f4d825', '#df4c0f', '#6f86b6', '#0e151d'],
  ['#e6edf6', '#9498a1', '#443a45', '#fe3b2c', '#74221f'],
  ['#f9cf94', '#feaf4b', '#de3211', '#2d337b', '#0a0904'],
  ['#efd5a1', '#f6c618', '#cd5183', '#5f91a6', '#1f5182'],
  ['#f8f1e2', '#e4e0c2', '#e7c600', '#dd1400', '#120d0a'],
  ['#df1c0a', '#fffefa', '#d79f90', '#77524c', '#262425'],
  ['#fde001', '#a2c551', '#696fa4', '#5e2e28', '#41293a'],
  ['#c24328', '#fcbc29', '#a2ab28', '#36907e', '#3b4e3f'],
  ['#96d5a2', '#80b588', '#ffa64c', '#f5410a', '#36251b'],
  ['#fff9d6', '#ffcc08', '#ff2212', '#103391', '#29241e'],
  ['#7e95bf', '#faa26c', '#964a34', '#df4120', '#2c150d'],
  ['#497896', '#4e6337', '#ee6e39', '#ce473e', '#562d2b'],
  ['#ead8ca', '#ceabb0', '#4d6181', '#c6b74f', '#f46f21'],
  ['#b75c22', '#468852', '#e1f3f6', '#749190', '#1d3929'],
  ['#cca37a', '#c57e02', '#c44207', '#726851', '#3f2412'],
  ['#f15a00', '#b43200', '#b6b2a9', '#555752', '#1b1f22'],
  ['#c87994', '#c3d2cf', '#52a29f', '#283730', '#0e0312'],
  ['#fda490', '#ff8885', '#811648', '#5e113d', '#051936'],
  ['#ebc56a', '#c93825', '#a1aec6', '#738ea4', '#5e5981'],
  ['#f05899', '#f1df02', '#eaa503', '#e44900', '#020158'],
]

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default colorleap
