// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {Array} Popular colors from ColourLovers.com API */
const colourlovers = [
  ['#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#FA6900'],
  ['#FE4365', '#FC9D9A', '#F9CDAD', '#C8C8A9', '#83AF9B'],
  ['#ECD078', '#D95B43', '#C02942', '#542437', '#53777A'],
  ['#556270', '#4ECDC4', '#C7F464', '#FF6B6B', '#C44D58'],
  ['#774F38', '#E08E79', '#F1D4AF', '#ECE5CE', '#C5E0DC'],
  ['#E8DDCB', '#CDB380', '#036564', '#033649', '#031634'],
  ['#490A3D', '#BD1550', '#E97F02', '#F8CA00', '#8A9B0F'],
  ['#594F4F', '#547980', '#45ADA8', '#9DE0AD', '#E5FCC2'],
  ['#00A0B0', '#6A4A3C', '#CC333F', '#EB6841', '#EDC951'],
  ['#E94E77', '#D68189', '#C6A49A', '#C6E5D9', '#F4EAD5'],
  ['#3FB8AF', '#7FC7AF', '#DAD8A7', '#FF9E9D', '#FF3D7F'],
  ['#D9CEB2', '#948C75', '#D5DED9', '#7A6A53', '#99B2B7'],
  ['#FFFFFF', '#CBE86B', '#F2E9E1', '#1C140D', '#CBE86B'],
  ['#EFFFCD', '#DCE9BE', '#555152', '#2E2633', '#99173C'],
  ['#343838', '#005F6B', '#008C9E', '#00B4CC', '#00DFFC'],
  ['#413E4A', '#73626E', '#B38184', '#F0B49E', '#F7E4BE'],
  ['#FF4E50', '#FC913A', '#F9D423', '#EDE574', '#E1F5C4'],
  ['#99B898', '#FECEA8', '#FF847C', '#E84A5F', '#2A363B'],
  ['#655643', '#80BCA3', '#F6F7BD', '#E6AC27', '#BF4D28'],
  ['#00A8C6', '#40C0CB', '#F9F2E7', '#AEE239', '#8FBE00'],
]

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default colourlovers
