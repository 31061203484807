// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import palettes from './palettes'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** Returns a random palette among all palettes, or from a specific provider if specified */
const getRandomPalette = (provider) => {
  if (!provider) {
    const keys = Object.keys(palettes)
    provider = palettes[keys[(Math.random() * keys.length) | 0]]
  }

  return provider[(Math.random() * provider.length) | 0]
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default getRandomPalette
