// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import muzli from './muzli'
import colourlovers from './colourlovers'
import adobe from './adobe'
import coolors from './coolors'
import colorhunt from './colorhunt'
import colorleap from './colorleap'
import colorpalettes from './colorpalettes'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {palettes} */
const palettes = {
  muzli,
  colourlovers,
  adobe,
  coolors,
  colorhunt,
  colorleap,
  colorpalettes,
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default palettes
