// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** @type {Array} Popular palettes taken from https://colors.muz.li/ */
const muzli = [
  ['#8a00d4', '#d527b7', '#f782c2', '#f9c46b', '#e3e3e3'],
  ['#e74645', '#fb7756', '#facd60', '#fdfa66', '#1ac0c6'],
  ['#454d66', '#309975', '#58b368', '#dad873', '#efeeb4'],
  ['#272643', '#ffffff', '#e3f6f5', '#bae8e8', '#2c698d'],
  ['#361d32', '#543c52', '#f55951', '#edd2cb', '#f1e8e6'],
  ['#072448', '#54d2d2', '#ffcb00', '#f8aa4b', '#ff6150'],
  ['#12492f', '#0a2f35', '#f56038', '#f7a325', '#ffca7a'],
  ['#122c91', '#2a6fdb', '#48d6d2', '#81e9e6', '#fefcbf'],
  ['#27104e', '#64379f', '#9854cb', '#ddacf5', '#75e8e7'],
  ['#f7a400', '#3a9efd', '#3e4491', '#292a73', '#1a1b4b'],
  ['#343090', '#5f59f7', '#6592fd', '#44c2fd', '#8c61ff'],
  ['#1f306e', '#553772', '#8f3b76', '#c7417b', '#f5487f'],
  ['#e0f0ea', '#95adbe', '#574f7d', '#503a65', '#3c2a4d'],
  ['#f9b4ab', '#fdebd3', '#264e70', '#679186', '#bbd4ce'],
  ['#492b7c', '#301551', '#ed8a0a', '#f6d912', '#fff29c'],
  ['#ffa822', '#134e6f', '#ff6150', '#1ac0c6', '#dee0e6'],
]

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default muzli
